import React, {useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {GooglePlayButton, AppStoreButton} from "react-mobile-app-button";

type FooterType = {};
const Footer: React.FC<FooterType> = () => {
    const [state, setState] = useState();

    return (
        <Container className={'footer'} fluid>
            <Row className={'mb-4'}>
                <Col/>
                <Col sm={4} md={6}>
                    <Col className={'d-flex justify-content-center mb-4 white'}>
                        <h4>Download the app now!</h4>
                    </Col>
                    <Row>
                        <Col className={'d-flex justify-content-center mb-2'}>
                            <AppStoreButton theme={'dark'} url={''}/>
                        </Col>
                        <Col className={'d-flex justify-content-center mb-2'}>
                            <GooglePlayButton theme={'dark'} url={''}/>
                        </Col>
                    </Row>
                </Col>
                <Col/>
            </Row>
            <Row>
                <Col/>
                <Col className={'white'}>
                    <p style={{fontSize:10, textAlign:'center'}}>{`© Copyright PCP Mileage Tracker \n${new Date().getFullYear()}`}</p>
                </Col>
                <Col/>
            </Row>

        </Container>
    );
};

export default Footer;

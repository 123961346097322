import React, {useState} from 'react';
import TopNavigationBar from "./TopNavigationBar";
import Footer from "./Footer";
import {Container} from "react-bootstrap";

type LayoutType = {
    children: React.ReactNode
};
const Layout: React.FC<LayoutType> = ({children}) => {
    const [state, setState] = useState();

    return (
        <Container style={{paddingLeft:0, paddingRight:0}} fluid>
            <TopNavigationBar/>
            {children}
            <Footer/>
        </Container>
    );
};

export default Layout;

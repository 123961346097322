import React, {useState} from 'react';
import {Navbar, Container, Nav}from 'react-bootstrap';
import {ReactSVG} from "react-svg";
import logoSvg from "../../images/logo.svg";

type TopNavigationBarType = {};
const TopNavigationBar: React.FC<TopNavigationBarType> = () => {
    const [state, setState] = useState();

    const path = window && window.location.pathname;

    return (
        <Navbar className="bg-light" expand={'lg'}>
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        src={logoSvg}
                        width={30}
                        height={30}
                        className={'d-inline-block align-top me-2'}
                    />
                    PCP Mileage Tracker
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id={"responsive-navbar-nav"} className="justify-content-end">
                    <Nav className={'ms-auto'}>
                        <Nav.Link href={'/'} active={path ==='/'}>
                            Home
                        </Nav.Link>
                        <Nav.Link href={'/privacy'} active={path==='/privacy/'}>
                            Privacy
                        </Nav.Link>
                        <Nav.Link href={'/terms'} active={path==='/terms/'}>
                            Terms and Conditions
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
};

export default TopNavigationBar;

// @ts-ignore
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {ThemeProvider} from "react-bootstrap";
import Layout from "./src/components/layout/Layout";
import './src/styles/main.scss';

export const wrapPageElement=({element, props})=>{
    return (
        <ThemeProvider >
            <Layout>
                {element}
            </Layout>
        </ThemeProvider>
    )
}
